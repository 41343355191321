import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { navigate } from "gatsby"

import { Card, Button, ListGroup, CardDeck, CardColumns } from "react-bootstrap"

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 345,
    fontFamily: "Montserrat",
  },
  media: {
    //height: 240,
    //width: 180,
    borderRadius: "8px",
    width: "180px",
    maxWidth: "180px",
    height: "250px",
    maxHeight: "250px",
    backgroundSize: "contain",
  },
  title: {
    width: "100%",
    fontFamily: "Arial Black",
    fontSize: 20,
  },
  btn: {
    width: "100%",
    height: "100%",
    color: "white",
    backgroundColor: "#ad1d21",
    borderColor: "#911619",
    fontFamily: "Montserrat",
    "&:hover": {
      backgroundColor: "#911619",
      borderColor: "#911619",
      boxShadow: "none",
    },
  },
}))

const SubProgram = ({ subProgram }) => {
  const classes = useStyles()
  if (!subProgram) return null
  const handleClick = link => () => {
    navigate(link)
  }
  return (
    <Card
    //border="success"
    >
      <Card.Header as="h5">{subProgram.title}</Card.Header>
      <Card.Img
        variant="top"
        src={subProgram.img}
        style={{
          maxHeight: "10rem",
          padding: "1px",
        }}
      />
      <Card.Body>
        <Card.Subtitle className="mb-2 text-muted">
          {subProgram.createdAt.split("T")[0]}
        </Card.Subtitle>
        <Card.Text>{subProgram.description}</Card.Text>
        <ListGroup style={{ marginBottom: "10px" }}>
          {subProgram.links && subProgram.links.split(",").length > 0 ? (
            <ListGroup.Item>Links complementarios</ListGroup.Item>
          ) : (
            <></>
          )}
          {subProgram.links && subProgram.links.split(",").length > 0 ? (
            subProgram.links.split(",").map(link => {
              return (
                <ListGroup.Item action href={link}>
                  {link}
                </ListGroup.Item>
              )
            })
          ) : (
            <></>
          )}
        </ListGroup>
        {subProgram.pdf ? (
          <Button
            variant="danger"
            size="lg"
            block
            onClick={() => {
              window.open(subProgram.pdf)
            }}
          >
            Leer el comunicado
          </Button>
        ) : (
          <></>
        )}

        {subProgram.links && subProgram.links.split(",").length > 0 ? (
          <Button size="lg" block>
            Links complementarios
          </Button>
        ) : (
          <></>
        )}
        <ListGroup>
          {subProgram.links && subProgram.links.split(",").length > 0 ? (
            subProgram.links.split(",").map(link => {
              return (
                <ListGroup.Item action href={link}>
                  {link}
                </ListGroup.Item>
              )
            })
          ) : (
            <></>
          )}
        </ListGroup>
      </Card.Body>
    </Card>
  )
}
export default SubProgram
