import React from "react"
import {Typography, Grid,} from '@material-ui/core'
import { makeStyles} from '@material-ui/core/styles';

import Layout from "../../components/layout"
import { useStaticQuery, graphql } from "gatsby";
import SubProgram from './subprogramTemplate'

import {
  Carousel,
  Container,
  Row,
  Col,
  //CardGroup,
  Card,
  CardDeck,
  Button,
  CardColumns

} from 'react-bootstrap';

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(2),
    //margin: theme.spacing(1),
    width:"70%",
    //color:"#0000",
    //sansSerif, 
    fontSize: "46px", 
    fontWeight: "100", 
    lineHeight: "50px", 
    letterSpacing: "1px", 
    padding: "0 0 40px", 
    //borderBottom: "double #555"
    fontFamily:"Arial Black"
    
    },
    spacingTextDescription:{
      marginBottom: theme.spacing(5),
      marginTop: theme.spacing(5),
    },
    font:{
      fontFamily:"Montserrat",
      width:"100%",
      textAlign: 'justify'

    },
    bodyText:{
      fontFamily:"Montserrat",
      textAlign: 'justify'
    },
    text:{
      //width:"70%",
      alignText:"center"
    },
    Noinfo:{
      width:"100%",
      fontFamily:"Montserrat",
      marginTop:"10px"
    }
}));

const Programs = () => {
  const classes = useStyles();   
  let programs = null
  programs= useStaticQuery(graphql`query{
    allRestApiApiBulletinsGeneralShowall {
    edges {
      node {
        data {
          bulletins {
            createdAt
            description
            id
            img
            pdf
            title
            updatedAt
            links
            uuid
            links
          }
        }
      }
    }
  }
  }`)
 //console.log(programs.allRestApiApiBulletinsGeneralShowall.edges[0].node.data.bulletins)//data.allRestApiApiProgramsShowall.edges[0].node.data)
  return(

    <Layout>
      <Typography className={classes.margin} variant="h5" align ="start">Comunicados</Typography>
      <div className={classes.text}>
      <h4>Comunicados Generales</h4>
      <p className={classes.font}>
      La comunicación es una pieza fundamental para ofrecerte un sindicalismo verdadero, transparente y eficaz. 
      En esta área, Comunicados Generales, encontrarás todos los posicionamientos, anuncios, noticias y campañas que el SITIMM lance e implemente en tu favor y el de tu familia.
      De esta forma, logramos que la información esté al alcance de tu mano y del resto de nuestras compañeras y compañeros afiliados, sin excepciones ni retrasos.
      </p>
      <h4>Comunicados Seccionales</h4>
      <p className={classes.font}>
       
        Los Comunicados Seccionales están disponibles únicamente a través de la App “Yo Soy SITIMM” (para Android o IOS), una vez que hayas iniciado sesión con tu RFC con homoclave. 
        Estos Comunicados son aquéllos que contienen información que sólo compete a ti y a tus compañeros de empresa. Ejemplos de éstos son: fechas de entrega de bienes en tu empresa, fechas de negociaciones colectivas, elección de nuevos Delegados(as) o Comisionados(as) Sindicales, etc.
        Te agradecemos que descargues la App “Yo Soy SITIMM” y la aproveches como un mecanismo de comunicación directa entre tú y tu Sindicato.
      </p>
      </div>
      {programs ? [
        <CardColumns>
        { 
         programs.allRestApiApiBulletinsGeneralShowall.edges[0].node.data.bulletins.map( subProgram => {
           return( 
            <SubProgram subProgram={subProgram} />
            )
         })}
        </CardColumns>
      ]:[
        <Typography className={classes.Noinfo} variant="h5" align ="center">Sin Comunicados Disponibles</Typography>
      ]}
      


    </Layout>
  )
}

export default Programs
